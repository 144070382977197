<template>
    <div>
        <div class="row mb-2" v-if="data.length > 0">
            <label
                class="btn btn-outline-primary btn-elevate btn-pill btn-sm mr-2"
                v-bind:class="{ active: modeNew }"
            >
                <input
                    style="display:none;"
                    v-model="modeNew"
                    type="checkbox"
                />
                Baru
            </label>
            <label
                class="btn btn-outline-danger btn-elevate btn-pill btn-sm"
                v-bind:class="{ active: modeKonfirmasi }"
            >
                <input
                    style="display:none;"
                    v-model="modeKonfirmasi"
                    type="checkbox"
                />
                Konfirmasi
            </label>
        </div>
        <div class="kt-widget-5 kt-scroll" style="height:300px" :id="id">
            <template v-if="listData.length > 0">
                <div
                    class="kt-widget-5__item pointer pt-3 pb-3 mb-2 mt-2"
                    :class="[
                        value.status == 'NEW'
                            ? 'kt-widget-5__item--info'
                            : 'kt-widget-5__item--danger',
                        value.isNewBO ? 'highlight' : ''
                    ]"
                    v-for="(value, index) in listData"
                    :key="index"
                    >
                    <div class="kt-widget-5__item-info">
                        <a href="#" class="kt-widget-5__item-title">
                            {{ value.namaPasien }} |
                            {{ value.nomorMedicalRecord }}
                        </a>
                        <div class="kt-widget-5__item-datetime">
                            {{ value.telp | filterPhone }}
                        </div>
                    </div>
                    <div class="kt-widget-5__item-info text-right">
                        <span class="kt-widget-5__item-title">
                            {{ value.jadwal ? value.jadwal.dokter.nama : "-" }}
                        </span>
                        <div
                            v-if="value.jadwal"
                            class="kt-widget-5__item-datetime"
                        >
                            {{ value.tanggalKunjungan | filterTanggal }}
                            {{
                                value.jadwal
                                    ? formatTime(value.jadwal.jamMulai)
                                    : "-"
                            }}
                            -
                            {{
                                value.jadwal
                                    ? formatTime(value.jadwal.jamSelesai)
                                    : "-"
                            }}
                        </div>
                        <div v-else class="kt-widget-5__item-datetime">
                            Jadwal Tidak Tersedia
                        </div>
                    </div>
                </div>
            </template>
            <div v-else class="d-flex justify-content-center">
                <div class="kt-iconbox">
                    <div class="kt-iconbox__icon">
                        <div class="kt-iconbox__icon-bg"></div>
                        <i class="flaticon-graph"></i>
                    </div>
                    <div class="kt-iconbox__title">
                        Data tidak ditemukan.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            listData: [],
            modeNew: true,
            modeKonfirmasi: true
        };
    },
    props: {
        data: { type: Array, default: [] },
        id: { type: String, default: "ScrollId" },
        startDate: { type: String },
        endDate: { type: String }
    },
    watch: {
        data(val) {
            this.generateData();
        },
        modeNew(val) {
            this.generateData();
        },
        modeKonfirmasi(val) {
            this.generateData();
        }
    },
    filters: {
        filterPhone: function(value) {
            return helpers.formatPhoneNumber(value);
        },
        filterTanggal: function(value) {
            return moment(value).format("DD MMM YYYY");
        }
    },
    methods: {
        formatTime: function(value) {
            return value != undefined ? value.substr(0, 5) : "";
        },
        goTo(value) {
            // console.log(value.jadwal.dokter);
            let obj = {
                date: value.tanggalKunjungan,
                dokter: value.jadwal.dokter,
                poli: value.jadwal.dokter.tujuanRujukan,
                type: 2,
                jamMulai: value.jadwal.jamMulai
            };
            sessionStorage.setItem("docAppo", JSON.stringify(obj));
            this.$store.commit(
                "DayCalendarStore/SET_APPOINT_FROM_DASHBOARD",
                value.id
            );
            setTimeout(() => {
                this.$router.push({ name: "doctor-appointment" });
            }, 100);
        },
        generateData: function() {
            let datax = [];
            if (this.modeNew && this.modeKonfirmasi) {
                datax = [...this.data];
            } else if (this.modeNew) {
                datax = this.data.filter(x => x.status == "NEW");
            } else if (this.modeKonfirmasi) {
                datax = this.data.filter(x => x.status == "CONFIRM");
            } else {
                datax = [];
            }
            this.listData = datax;
            this.listData.sort((a, b) => {
                return b.id - a.id;
            });
        }
    },
    mounted() {
        console.log();
        var vx = this;
        var container = document.querySelector("#" + vx.id);
        const ps1 = new PerfectScrollbar(container, {
            minScrollbarLength: false
        });
        this.generateData();
    }
};
</script>

<style></style>
