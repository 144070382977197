var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data.length > 0)?_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"btn btn-outline-primary btn-elevate btn-pill btn-sm mr-2",class:{ active: _vm.modeNew }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modeNew),expression:"modeNew"}],staticStyle:{"display":"none"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modeNew)?_vm._i(_vm.modeNew,null)>-1:(_vm.modeNew)},on:{"change":function($event){var $$a=_vm.modeNew,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modeNew=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modeNew=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modeNew=$$c}}}}),_vm._v(" Baru ")]),_c('label',{staticClass:"btn btn-outline-danger btn-elevate btn-pill btn-sm",class:{ active: _vm.modeKonfirmasi }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modeKonfirmasi),expression:"modeKonfirmasi"}],staticStyle:{"display":"none"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modeKonfirmasi)?_vm._i(_vm.modeKonfirmasi,null)>-1:(_vm.modeKonfirmasi)},on:{"change":function($event){var $$a=_vm.modeKonfirmasi,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modeKonfirmasi=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modeKonfirmasi=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modeKonfirmasi=$$c}}}}),_vm._v(" Konfirmasi ")])]):_vm._e(),_c('div',{staticClass:"kt-widget-5 kt-scroll",staticStyle:{"height":"300px"},attrs:{"id":_vm.id}},[(_vm.listData.length > 0)?_vm._l((_vm.listData),function(value,index){return _c('div',{key:index,staticClass:"kt-widget-5__item pointer pt-3 pb-3 mb-2 mt-2",class:[
                    value.status == 'NEW'
                        ? 'kt-widget-5__item--info'
                        : 'kt-widget-5__item--danger',
                    value.isNewBO ? 'highlight' : ''
                ]},[_c('div',{staticClass:"kt-widget-5__item-info"},[_c('a',{staticClass:"kt-widget-5__item-title",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(value.namaPasien)+" | "+_vm._s(value.nomorMedicalRecord)+" ")]),_c('div',{staticClass:"kt-widget-5__item-datetime"},[_vm._v(" "+_vm._s(_vm._f("filterPhone")(value.telp))+" ")])]),_c('div',{staticClass:"kt-widget-5__item-info text-right"},[_c('span',{staticClass:"kt-widget-5__item-title"},[_vm._v(" "+_vm._s(value.jadwal ? value.jadwal.dokter.nama : "-")+" ")]),(value.jadwal)?_c('div',{staticClass:"kt-widget-5__item-datetime"},[_vm._v(" "+_vm._s(_vm._f("filterTanggal")(value.tanggalKunjungan))+" "+_vm._s(value.jadwal ? _vm.formatTime(value.jadwal.jamMulai) : "-")+" - "+_vm._s(value.jadwal ? _vm.formatTime(value.jadwal.jamSelesai) : "-")+" ")]):_c('div',{staticClass:"kt-widget-5__item-datetime"},[_vm._v(" Jadwal Tidak Tersedia ")])])])}):_c('div',{staticClass:"d-flex justify-content-center"},[_vm._m(0)])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-iconbox"},[_c('div',{staticClass:"kt-iconbox__icon"},[_c('div',{staticClass:"kt-iconbox__icon-bg"}),_c('i',{staticClass:"flaticon-graph"})]),_c('div',{staticClass:"kt-iconbox__title"},[_vm._v(" Data tidak ditemukan. ")])])
}]

export { render, staticRenderFns }